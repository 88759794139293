import React, {useRef, useEffect, useState} from "react";
import {scroll, animate} from 'motion'
import bt from './images/BetterTech Screen Shot.png'
import btCRM from './images/BetterTech CRM Screen Shot.png'
import btApp from './images/BudgetWhileYouShopApp.png'
import tomPointing from './images/alexPointingWithChatBubble.png'
import paradisePOS from './images/Paradise POS Topper Mobile and Store Shortrer.png'
import StickyHeader from './components/header'

function App() {
  const [loading, setLoading] = useState(true)
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [elmId, setElmId] = useState('')
  const popupRef = useRef(null);
 
  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const posx = clientX
    const posy = clientY
    setMousePosition({ x: posx, y: posy });
  };
  const handleMouseEnter = (data) => {
    setIsPopupVisible(false)
    try{
      const alexPointing = document.getElementById(data)
      animate(alexPointing,{height: "260px"}, {duration: .5})    
    }catch{}
  }
  const handleMouseLeave = (data) => {  
    setIsPopupVisible(true)
    try{
      const alexPointing = document.getElementById(data)
      animate(alexPointing,{height: "0px"}, {duration: .5})
    }catch{}
  };
  
  
  
  const windowSize = window.innerWidth
  
 
  
  useEffect(()=>{
    
    setLoading(false)
    scroll(
      animate(".alexPhotoContainer", { y: (windowSize*0.5), scale: [1, 0] }),
      {
        target: document.getElementById('gridArea1'),
        offset: ["start 0", "end 0.2"]
      },
      );
            
        const gridOne = document.querySelectorAll('.gridArea')
        scroll(
          animate(gridOne, { backgroundColor: 'rgb(29,29,135)' }),
          {
            target: document.getElementById('gridArea1'),
            offset: ["start 0", "end 0.55"]
          },
          );
          scroll(
            animate(gridOne, { backgroundColor: 'black' }),
      {
        target: document.getElementById('gridArea2'),
        offset: ["start 0", "end .5"]
      },
      );
      scroll(
        animate(gridOne, { backgroundColor: 'rgb(7,156,162)'}),
        {
          target: document.getElementById('gridArea3'),
          offset: ["start 0", "end 0.55"]
        },
        );
        const words = document.querySelectorAll('h1,h2,h3,h4,p')
        scroll(
          animate(words, { color: 'black'}),
          {
            target: document.getElementById('gridArea3'),
            offset: ["start 0", "end 0.55"]
          },
          );
        },[])
        
        
        return(
          
          <>
      <header>
        <div style={{width:"100%"}}>
          <StickyHeader></StickyHeader>
        </div>
      </header>
          
      <main>
        <div className="alexPhotoContainer" id="alexPhoto" style={
          {width: windowSize, backgroundSize: "auto 100vh"}
        }>
        </div>

        <div id="gridArea1" className="gridArea">
          
        </div>  

        <div id='gridArea2' className="gridArea">
          <div className="topRowCenter">
            <h1>Hello!</h1>
            <h2>My name is <br></br>Thomas "Alex" Grissom</h2>
            <p>I am a Lufkin, Tx based Web Developer.</p>
          </div>
          <div className="midRowCenter">
            <h2>I specilize in solving your<br/>specific problems with custom solutions</h2>
          </div>
        </div>        
        <div id='gridArea3' className="gridArea">
          <div className="topRowCenter" style={{alignSelf: "center"}}>
            <h1>WORK</h1>
            <h4>Founder and Project Manager for BetterTech</h4>
            <p><i>"Better Solutions, Better Prices, BetterTech"</i></p>
            <p>BetterTech services local businesses with website management, Point Of Sale and more!</p>
          </div>
          
          <div 
            id="btSitePhotoBox"
            className="gridPhoto" 
            onMouseEnter={(e)=>handleMouseEnter("alexPointingBtSite")}
            onMouseLeave={(e)=>handleMouseLeave("alexPointingBtSite")}
            onMouseMove={handleMouseMove}>
              <a href="https://www.bettertechsol.com/" target='blank'><img alt="BetterTech Website Screenshot" id="btSite" src={bt}/></a>
          </div>
            
            <div 
              className="alexPointing" 
              id="alexPointingBtSite" 
              style={{
                      position: "relative",
                      left: mousePosition.x,
                      zIndex: 9,
                      pointerEvents: "none",
                  }}>
                <img
                alt="" 
                  src={tomPointing} 
                  style={{
                          pointerEvents: "none",
                          width: '100%',
                          height: '100%',
                          transform: "translate(0, 0)",
                        }}
                  ></img>
            </div>

        </div>      
          
        <div id='gridArea4' className="gridArea">
          <div className="topRowCenter" style={{alignSelf: "center"}}>
            <h1>Projects</h1>
            <h4>BetterTech CRM</h4>
            <p>This is a reactJS based webapp. Built with Firebase authentication and Firestore Database.
              <br/> It will be used as a consumer relations management software.
              <br/>Also, it will be the foundation of all future apps we build!
              <br/> <i>(currently in Alpha testing)</i></p>
          </div>
          <div 
              className="gridPhoto"
              onMouseEnter={(e)=>handleMouseEnter("alexPointingBtCRM")}
              onMouseLeave={(e)=>handleMouseLeave("alexPointingBtCRM")}
              onMouseMove={handleMouseMove}>
            <a id="btCRM" href="https://www.tomtech.tech/" target='blank'><img alt="BetterTech Software Demo Screenshot" id="btCRM" src={btCRM}/></a>
          </div>
          <div 
              className="alexPointing" 
              id="alexPointingBtCRM" 
              style={{
                      position: "relative",
                      left: mousePosition.x,
                      zIndex: 9,
                      pointerEvents: "none",
                  }}>
                <img 
                alt=""
                  src={tomPointing} 
                  style={{
                          pointerEvents: "none",
                          width: '100%',
                          height: '100%',
                          transform: "translate(0, 0)",
                        }}
                  ></img>
            </div>
        </div>  

        <div id='gridArea4.5' className="gridArea">
          <div className="topRowCenter" style={{alignSelf: "center"}}>
            <h4>Budget While You Shop Phone App</h4>
            <p><i>(Available On Android, Comming soon to IOS)</i></p>
            <p>If you are on a tight budget, this app is for you! 
              <br/>This is a very simple and straightforward app for tracking your spending
              <br/>as you shop in person and physically add items to your basket!
            </p>
          </div>
          <div 
              className="gridPhoto"
              onMouseEnter={(e)=>handleMouseEnter("alexPointingbtApp")}
              onMouseLeave={(e)=>handleMouseLeave("alexPointingbtApp")}
              onMouseMove={handleMouseMove}>
            <a id="btApp" href="https://play.google.com/store/apps/details?id=com.alexbettertech.native_test01" target='blank'><img alt="Budget While You Shop App Screen Shot of Google Play Store" id="btApp" src={btApp}/></a>
          </div>
          <div 
              className="alexPointing" 
              id="alexPointingbtApp" 
              style={{
                      position: "relative",
                      left: mousePosition.x,
                      zIndex: 9,
                      pointerEvents: "none",
                  }}>
                <img 
                alt=""
                  src={tomPointing} 
                  style={{
                          pointerEvents: "none",
                          width: '100%',
                          height: '100%',
                          transform: "translate(0, 0)",
                        }}
                  ></img>
            </div>
        </div>  

        <div id='gridArea5' className="gridArea">
          <div className="topRowCenter" style={{alignSelf: "center"}}>
            <h1>Partners</h1>
            <h4>Paradise Point Of Sale</h4>
            <p>ParadisePOS is a Texas Based Point Of Sale system provided by Secure Solutions of Tyler Texas</p>
            <p>BetterTech is an area representative</p>
          </div>
          <div 
              className="gridPhoto"
              onMouseEnter={(e)=>handleMouseEnter("alexPointingUNNAMED")}
              onMouseLeave={(e)=>handleMouseLeave("alexPointingUNNAMED")}
              onMouseMove={handleMouseMove}>
            <a href="https://www.bettertechsol.com/Merchant-Services#ParadisePointOfSale" target='blank'><img alt="paradise point Of sale screenshot" id="btCRM2" src={paradisePOS}/></a>
          </div>
          <div 
              className="alexPointing" 
              id="alexPointingUNNAMED" 
              style={{
                      position: "relative",
                      left: mousePosition.x,
                      zIndex: 9,
                      pointerEvents: "none",
                  }}>
                <img 
                alt=""
                  src={tomPointing} 
                  style={{
                          pointerEvents: "none",
                          width: '100%',
                          height: '100%',
                          transform: "translate(0, 0)",
                        }}
                  ></img>
            </div>
        </div>        
        <div id='gridArea6' className="resumeArea">
          <div className="topRowCenter">
            <h1>RESUME</h1>
            <div className="flexBoxResume">
              <div>
                <h1>Thomas Alexander Grissom</h1>
              </div>
              <div>
                <h2>Summary</h2>
                <p>Exceptional partner with proven skills in team leadership, creating and maintaining customer relationships, understanding of business mathematics, negotiation and client privacy. Outstanding and unique customer service, and  organizational skills. Demonstrated ability to utilize and code with current technology including Excel, Word and Google Docs and web. Creative thinker, driven to succeed through a desire to learn new skills and enhance my value to an employer. High levels of discretion and client privacy have been essential to successful relationships and negotiations.</p>
              </div>
            </div>
            <div className="flexBoxResume">
              
              <div>
                <h2>All Work History</h2>
                <p style={{textAlign: "left"}}>
                  <ul>
                    <li style={{marginLeft: "5%"}}><strong>Founder of BetterTech Web Solutions</strong></li>
                    <div style={{marginLeft: "10%"}}><li>Duties consist of full stack development for clients online business requirements</li></div>
                    <div style={{marginLeft: "10%"}}><li>SEO Monitoring and Management </li></div>
                    <div style={{marginLeft: "10%"}}><li>eCommerce Design and Support for clients</li></div>
                    <div style={{marginLeft: "10%"}}><li>Custom programming in HTML, CSS, PHP, SQL JavaScript, and TwiML</li></div>
                    <div style={{marginLeft: "10%"}}><li>Marketing and Sales</li></div>
                    <div style={{marginLeft: "10%"}}><li>Branding</li></div>
                    <div style={{marginLeft: "10%"}}><li>And all the hats it takes to be a single business owner!</li></div>
                    <div style={{marginLeft: "10%"}}><li>See <a style={{color: "blue"}}href="https://www.bettertechsol.com/">BetterTechSol.com</a> For more</li></div>
                    <li style={{marginTop: "20px", marginLeft: "5%"}}><strong>Freelance Tech Support / Google Products / Programming (10+ years)</strong></li>
                    <div style={{marginLeft: "10%"}}><li>Sheets, Docs, Slides, forms, sites, apps scripts, etc.+</li></div>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>Freelance Tech Support / Google Products / Programming (10+ years)</strong></li>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>Mobile Home Masters- Contracted Tech Support / Data Analyst / Land Realtor and mobile home sales (2021)</strong></li>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>Licensed Real Estate Sales Agent, REALTOR Current since January 2018 (& Property Manager 2020)</strong></li>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>District Manager Cottonwood Financial LTC. - DBA. Cash Store</strong></li>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>Store Manager, CVS/Health</strong></li>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>Assistant Store Manager, CVS/Caremark</strong></li>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>Gerimed DME sales (durable medical Equipment)</strong></li>
                    <li style={{marginTop: "20px",marginLeft: "5%"}}><strong>Certified Pharmacy Technician, CVS/Caremark</strong></li>
                  </ul>
                </p>
                <h2 style={{marginTop: "20px"}}>Education</h2>
                <p style={{textAlign: "left"}}>
                  <ul>
                    <li style={{marginLeft: "5%"}}>Yo Hablo Español</li>
                    <li style={{marginLeft: "5%"}}>Independent Study - <i>Always on going</i></li>
                    <li style={{marginLeft: "5%"}}>San Augustine High School - Graduated 2006</li>
                    <li style={{marginLeft: "5%"}}>National Pharmacy Technician certification #420101071262922</li>
                    <li style={{marginLeft: "5%"}}>Texas Pharmacy License #147637- Licensure obtained 2007</li>
                    <li style={{marginLeft: "5%"}}>Texas Licensed Real Estate Sales Agent #699615</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>        
              
               
    </main>
               
  </>
      )
      
    }

    export default App;

