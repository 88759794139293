import { useState, useEffect } from 'react';
import {scroll, animate} from 'motion'
import { motion } from 'framer-motion';
import tgps from '../images/TGPS.png'
import alexVcard from '../images/alex pointing DL vcard.png'
import VCFDownload from './vcard'

function StickyHeader() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  
    
  useEffect(()=>{
    const stickyHeaderHam = document.querySelectorAll("span")
    const logoBackground = document.getElementsByClassName("sticky-header__logoIMG")
    const alexPointingVcard = document.getElementsByClassName("alexPointingVcard")
    animate(alexPointingVcard,{opacity: 0},{duration: 1, delay: 4})
    scroll(
      animate(stickyHeaderHam, { backgroundColor: 'blue' }),
      {
        target: document.getElementById('gridArea1'),
        offset: ["start 0", "end 0.55"]
      },
    );
    scroll(
      animate(stickyHeaderHam, { backgroundColor: 'black' }),
      {
        target: document.getElementById('gridArea3'),
        offset: ["start 0", "end 0.55"]
      },
    );
    scroll(
      animate(logoBackground, { filter: "drop-shadow(0px 0px 3px white)" }),
      {
        target: document.getElementById('gridArea1'),
        offset: ["start 0", "end 0.55"]
      },
    );
    scroll(
      animate(logoBackground, { filter: "none" }),
      {
        target: document.getElementById('gridArea3'),
        offset: ["start 0", "end 0.55"]
      },
    );
  },[])

  return (
    <header className="sticky-header">
      <div className="sticky-header__logo"><img className='sticky-header__logoIMG' src={tgps}/></div>
      <motion.div
        className="sticky-header__hamburger"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        animate={{ rotate: isMenuOpen ? 45 : 0 }}
      >
        <span />
        <span />
        <span />
      </motion.div>
      {isMenuOpen && (
        <>
          <div className="sticky-header__menu">
            <p className='sticky-header_content' style={{color:"black"}}>Alex Grissom</p>
            <a className='sticky-header_content' href='tel:+19362294774' style={{color:"black"}}>936.229.4774</a>
            <a className='sticky-header_content' href='mailto:alex@tomgrissom.com' style={{color:"black"}}>Alex@TomGrissom.com</a>
            <a className='sticky-header_content' href='mailto:Alex@bettertechsol.com' style={{color:"black"}}>Alex@BetterTechSol.com</a>
            <VCFDownload/>
          </div>
        </>
      )}
      
        <div className='alexPointingVcard'>
          <img id="alexVcard" src={alexVcard} hidden={isMenuOpen}/>
        </div>

    </header>
  );
}
export default StickyHeader;