import React from "react";

function createVCardString() {
  const vCardData = [
    "BEGIN:VCARD",
    "VERSION:4.0",
    "N:Grissom;Alex;;;",
    "FN:Alex Grissom",
    "ORG:BetterTechSol.com",
    "TITLE:Web Developer",
    "EMAIL;type=INTERNET;type=WORK;type=pref:Alex@BetterTechSol.com;",
    "TEL;type=WORK:+1 936.299.4774;",
    "TEL;type=CELL:+1 903.503.0878;",
    "NOTE:Alex is a Web developer, and also knows Spanish! - Thanks for Adding me to your contacts! ;)",
    "item3.URL;type=pref:http\://www.BetterTechSol.com",
    "CATEGORIES:work,friend,website help",
"   PHOTO;VALUE=URI;TYPE=JPEG:https://drive.google.com/u/0/uc?id=1Yb5OfBnlXujyZbJjaEOxUQefo-QdZ--2&export=download",    
    "END:VCARD",
  ].join("\n");

  return vCardData;
}

function createVCardURI() {
  const vCardString = createVCardString();
  const vCardBlob = new Blob([vCardString], { type: "text/vcard;charset=utf-8" });
  const vCardURI = URL.createObjectURL(vCardBlob);
  return vCardURI;
}

function VCFDownload() {
  const vCardURI = createVCardURI();

  return (
    <a  href={vCardURI} 
        download="contact.vcf"
        className='sticky-header_content' 
        style={{border: "3px solid black", textAlign:"center", color:"black", background: "rgb(13,255,255)"}}
    ><strong>Add Contact Data</strong></a>
  );
}

export default VCFDownload;
